const AuthReducer = (state, action) => {
    switch (action.type) {
        case "SET_USER":
            return {
                ...state,
                currentUser: action.payload.currentUser,
                userType: action.payload.userType,
            };
        default:
            return state;
    }
};

export default AuthReducer;