import React, { useState, useContext } from 'react';
import "./style.css";
import { auth } from "../../Config/Config";
import { useNavigate } from "react-router-dom";
import HomeNavbar from '../../Component/HomeNavbar';
import Loader from '../../Component/Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { InputLabel, TextField } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import DBHandler from '../../Utils/DBHandler';
import { AuthContext } from '../../Context/AuthContext';

export default function AddQuestion({ setIsLoginOpen, setIsSubsOpen }) {
    const [data, setData] = useState({
        heading: '',
        description: '',
        subtitle: '',
        additionalInfo: '',
    });

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const dbHandler = new DBHandler();
    const { currentUser } = useContext(AuthContext);

    const handleInput = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        setData({ ...data, [id]: value });
    };

    const handleAdd = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const authUser = auth.currentUser;
        if (!currentUser.isSubscribed) {
            setIsLoading(false);
            toast.error('Please Subscribe to add a question');
            setIsSubsOpen(true);
            return;
        }

        if (authUser) {
            try {
                await dbHandler.addUserQuestion(currentUser, data);
                setIsLoading(false);
                toast.success(
                    'Successfully Added the Product. You will now automatically get redirected to the Product page'
                );
                setTimeout(() => {
                    navigate('/');
                }, 1500);
            } catch (err) {
                setIsLoading(false);
                toast.error(err.message);
            }
        }
    };

    return (
        <>
            <div className="home">
                <HomeNavbar setIsLoginOpen={setIsLoginOpen} currentUser={currentUser} setIsSubsOpen={setIsSubsOpen} />
                <ToastContainer
                    position="top-right"
                    autoClose={2400}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light" />
                <div className="homeContainer">
                    <div className="container-fluid px-1 py-5 mx-auto">
                        <div className="row d-flex justify-content-center">
                            <div className="col-xl-7 col-lg-8 col-md-9 col-11 text-center" style={{ paddingTop: '100px', width: '85%' }}>
                                <div className="prdcard">
                                    <h1>Add New Question</h1>
                                    <form className="form-card" onSubmit={handleAdd}>
                                        <div className="row justify-content-between text-left">
                                            <div className="form-group col-sm-12 flex-column d-flex mt-3">
                                                <InputLabel htmlFor="heading" className="form-control-label px-3 form-control-label-ques">
                                                    Heading<span className="text-danger"> *</span>
                                                </InputLabel>
                                                <TextField
                                                    type="text"
                                                    id="heading"
                                                    name="heading"
                                                    placeholder="Problem Heading"
                                                    onChange={handleInput}
                                                    value={data.heading}
                                                    variant="outlined"
                                                    required
                                                    className="text-field-ques"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-sm-12 flex-column d-flex mt-3">
                                            <InputLabel htmlFor="description" className="form-control-label px-3 form-control-label-ques">
                                                Description<span className="text-danger"> *</span>
                                            </InputLabel>
                                            <TextField
                                                type="text"
                                                id="description"
                                                name="description"
                                                placeholder="Problem Description"
                                                onChange={handleInput}
                                                value={data.description}
                                                variant="outlined"
                                                multiline
                                                rows={15}
                                                required
                                                className="text-field-ques"
                                            />
                                        </div>
                                        <div className="form-group col-sm-12 flex-column d-flex mt-3">
                                            <InputLabel htmlFor="additionalInfo" className="form-control-label px-3 form-control-label-ques">
                                                Additional Info
                                            </InputLabel>
                                            <TextField
                                                type="text"
                                                id="additionalInfo"
                                                name="additionalInfo"
                                                placeholder="Additional Info like Constraints, Test Cases etc."
                                                onChange={handleInput}
                                                value={data.additionalInfo}
                                                variant="outlined"
                                                multiline
                                                rows={5}
                                                className="text-field-ques"
                                            />
                                        </div>
                                        <div className="row justify-content-center mt-5">
                                            <div className="form-group col-sm-6">
                                                <button type="submit" className="btn-block btn-primary-ques">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading && <Loader />}
        </>
    )
}
