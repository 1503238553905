import React from 'react';
import './style.css';

export default function Subscription({ openSubs, onCloseSubs }) {
    if (!openSubs) return null;

    return (
        <div className="modal-overlay-subs" onClick={onCloseSubs}>
            <div className="modal-content-subs" onClick={(e) => e.stopPropagation()}>
                <div className="modal-subs">
                    <form className="form-subs">
                        <div className="banner-subs"></div>
                        <label className="title-subs">Become an InstantCode Member</label>
                        <p className="description-subs">
                            Join InstantCode to get instant support for your coding questions. Become a part of our dynamic community of developers who are here to help you understand and solve your coding problems!
                        </p>
                        {/* <div className="tab-container-subs">
                            <button className="tab tab--1">Monthly</button>
                            <button className="tab tab--2">Annual</button>
                            <div className="indicator-subs"></div>
                        </div> */}

                        <div className="benefits-subs">
                            <span>What we offer</span>
                            <ul>
                                <li>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" height="16" width="16">
                                        <rect fill="black" rx="8" height="16" width="16"></rect>
                                        <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1.5" stroke="white" d="M5 8.5L7.5 10.5L11 6"></path>
                                    </svg>
                                    <span>Get instant support for your query</span>
                                </li>
                                <li>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" height="16" width="16">
                                        <rect fill="black" rx="8" height="16" width="16"></rect>
                                        <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1.5" stroke="white" d="M5 8.5L7.5 10.5L11 6"></path>
                                    </svg>
                                    <span>Dynamic community of developers & coders</span>
                                </li>
                                <li>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" height="16" width="16">
                                        <rect fill="black" rx="8" height="16" width="16"></rect>
                                        <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1.5" stroke="white" d="M5 8.5L7.5 10.5L11 6"></path>
                                    </svg>
                                    <span>Lifetime access to InstantCode!!</span>
                                </li>
                            </ul>
                        </div>

                        <div className="modal-footer-subs">
                            <label className="price-subs"><sup>₹</sup>3000</label>
                            <button className="upgrade-btn-subs">Upgrade to PRO</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
