import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useState, useContext } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import ScrollTop from "./Component/ScrollTop";
import ScrollTopPage from './Component/ScrollTopPage';
import Home from "./Pages/Home/Home";
// import { AuthContext } from "./Context/AuthContext";
import Error from "./Component/Error";
import Login from './Pages/Login/Login';
import SignUp from './Pages/Login/SignUp';
import Subscription from './Pages/Subscription/Subscription';
import AddQuestion from './Pages/Question/AddQuestion';
import { AuthContext } from './Context/AuthContext';
import Maintenance from './Pages/Maintenance/Maintenance';

function App() {
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const [isSubsOpen, setIsSubsOpen] = useState(false);

  const { currentUser } = useContext(AuthContext);

  function requireUser(userType, allowedTypes, component) {
    if (allowedTypes.includes(userType)) {
      return component;
    } else {
      return <Navigate to="/error" />;
    }
  }

  return (
    <>
      <Router>
        <ScrollTop />
        <Routes>
        <Route path="/" element={<Maintenance/>} />
          {/* <Route path="/" element={<Home setIsLoginOpen={setIsLoginOpen} setIsSubsOpen={setIsSubsOpen} />} /> */}
          <Route path="/newQues" element={requireUser(currentUser && currentUser.userType, ["User"], <AddQuestion setIsLoginOpen={setIsLoginOpen} setIsSubsOpen={setIsSubsOpen} />)} />
          <Route path="/error" element={<Error />} />
        </Routes>
        <ScrollTopPage />
        <Login open={isLoginOpen} onClose={() => setIsLoginOpen(false)} setIsSignUpOpen={setIsSignUpOpen} setIsSubsOpen={setIsSubsOpen} />
        <SignUp openSignUp={isSignUpOpen} onCloseSignUp={() => setIsSignUpOpen(false)} setIsLoginOpen={setIsLoginOpen} setIsSubsOpen={setIsSubsOpen} />
        <Subscription openSubs={isSubsOpen} onCloseSubs={() => setIsSubsOpen(false)} />
      </Router>
    </>
  );
}

export default App;
