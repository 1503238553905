import React from 'react';
import './Maintenance.css';

function Maintenance() {
  return (
    <div className="image-container">
      <picture>
        <source media="(max-width: 768px)" srcSet={`${process.env.PUBLIC_URL}/mobile.png`} />
        <source media="(min-width: 769px)" srcSet={`${process.env.PUBLIC_URL}/maintenance.png`} />
        <img src={`${process.env.PUBLIC_URL}/maintenance.png`} alt="Responsive" />
      </picture>
    </div>
  );
}

export default Maintenance;
