import { GoogleAuthProvider } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB20AqqCh6DETmC_VOckTxU47DGWGitYUY",
  authDomain: "instantcode-1.firebaseapp.com",
  projectId: "instantcode-1",
  storageBucket: "instantcode-1.appspot.com",
  messagingSenderId: "529546373920",
  appId: "1:529546373920:web:d182c9b411101f44748545",
  measurementId: "G-2TWRM4PTPL"
};

const initialize = firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
const provider = new GoogleAuthProvider();

export { initialize, auth, db, storage, provider };
