import React, { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../App.css';

export default function ScrollTopPage() {
    const scrollTopRef = useRef(null);
    const location = useLocation();

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    function toggleScrollTop() {
        const scrollTop = scrollTopRef.current;
        if (scrollTop) {
            window.scrollY > 100 ? scrollTop.classList.add('active') : scrollTop.classList.remove('active');
        }
    }

    useEffect(() => {
        window.addEventListener('load', toggleScrollTop);
        window.addEventListener('scroll', toggleScrollTop);

        return () => {
            window.removeEventListener('load', toggleScrollTop);
            window.removeEventListener('scroll', toggleScrollTop);
        };
    }, []);

    return (
        <Link
            aria-current="page"
            id="scroll-top"
            to={location.pathname}
            ref={scrollTopRef}
            className="scroll-top d-flex align-items-center justify-content-center"
            onClick={handleScrollToTop}
        >
            <i className="bi bi-arrow-up-short"></i>
        </Link>
    );
}
