import React, { useEffect, useState, useContext } from 'react';
import { Button } from '@mui/material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import '../App.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { auth } from "../Config/Config";
import { AuthContext } from '../Context/AuthContext';

export default function HomeNavbar({ setIsLoginOpen, currentUser, setIsSubsOpen }) {
    const location = useLocation();
    const [isMobile, setIsMobile] = useState(false);
    const { dispatch } = useContext(AuthContext);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const isUser = !!currentUser?.id;

    const handleLogOut = () => {
        auth.signOut().then(() => {
            dispatch({ type: "LOGOUT" });
            navigate("/");
        });
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        const handleScroll = () => {
            const header = document.querySelector('#header');
            if (header) {
                window.scrollY > 100 ? header.classList.add('sticked') : header.classList.remove('sticked');
            }
        };

        const handleNavbarLinksActive = () => {
            const navbarLinks = document.querySelectorAll('#navbar a');
            navbarLinks.forEach(navbarLink => {
                if (!navbarLink.hash) return;
                const section = document.querySelector(navbarLink.hash);
                if (!section) return;
                const position = window.scrollY + 200;
                if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
                    navbarLink.classList.add('active');
                } else {
                    navbarLink.classList.remove('active');
                }
            });
        };

        handleNavbarLinksActive();
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('load', handleNavbarLinksActive);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const mobileNavToggle = () => {
        document.querySelector('body').classList.toggle('mobile-nav-active');
        const mobileNavShow = document.querySelector('.mobile-nav-show');
        const mobileNavHide = document.querySelector('.mobile-nav-hide');
        mobileNavShow.classList.toggle('d-none');
        mobileNavHide.classList.toggle('d-none');
    };

    const handleSamePageLinkClick = () => {
        if (document.querySelector('.mobile-nav-active')) {
            mobileNavToggle();
        }
    };

    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <div className="container d-flex align-items-center justify-content-between">
                    <i className="mobile-nav-toggle mobile-nav-show bi bi-list" onClick={mobileNavToggle}></i>
                    <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x" onClick={mobileNavToggle}></i>
                    <Link
                        aria-current="page"
                        to="/"
                        className="logo d-flex align-items-center me-auto me-lg-0"
                    >
                        <h1>InstantCode<span>.</span></h1>
                    </Link>
                    <nav id="navbar" className="navbar">
                        <ul>
                            <li>
                                <Link
                                    aria-current="page"
                                    to="/"
                                    className={location.pathname === "/" ? "active" : ""}
                                    onClick={handleSamePageLinkClick}
                                >
                                    Home
                                </Link>
                            </li>
                            <li>
                                {currentUser && currentUser.isSubscribed && (
                                    <Link
                                        aria-current="page"
                                        to="/"
                                        className={location.pathname === "/archives" ? "active" : ""}
                                        onClick={handleSamePageLinkClick}
                                    >
                                        Archives
                                    </Link>
                                )}
                            </li>
                            <li>
                                {currentUser && currentUser.isSubscribed && (
                                    <Link
                                        aria-current="page"
                                        to="/newQues"
                                        className={location.pathname === "/newQues" ? "active" : ""}
                                        onClick={handleSamePageLinkClick}
                                    >
                                        Add Question
                                    </Link>
                                )}
                            </li>
                        </ul>
                    </nav>
                    {isUser ? (
                        <>
                            <IconButton
                                color="inherit"
                                aria-label="user account"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                className='icon-btn-navbar'
                            >
                                <Avatar
                                    sx={{ width: 40, height: 40, color: 'white', backgroundColor: '#199FD9', fontWeight: 'bold' }}
                                    alt={isUser.name}
                                    src={isUser.photoURL}
                                >
                                    {currentUser.name ? currentUser.name[0] : 'U'}
                                </Avatar>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                {currentUser && currentUser.isSubscribed && (
                                    <Link to="/myQuestions" style={{ color: 'grey' }}>
                                        <MenuItem onClick={handleClose} sx={{ width: '200px' }}>
                                            Questions
                                        </MenuItem>
                                    </Link>
                                )}
                                {currentUser && !currentUser.isSubscribed && (
                                    <Link onClick={() => setIsSubsOpen(true)} style={{ color: 'grey' }}>
                                        <MenuItem onClick={handleClose} sx={{ width: '200px' }}>
                                            Subscription
                                        </MenuItem>
                                    </Link>
                                )}
                                <MenuItem onClick={handleLogOut} sx={{ width: '200px', color: 'red' }}>Logout</MenuItem>
                            </Menu>
                        </>
                    ) : (
                        <Button
                            onClick={() => setIsLoginOpen(true)}
                            aria-current="page"
                            className={`btn-join-us ${isMobile ? 'mobile-only' : ''}`}
                        >
                            {isMobile ? "Sign In" : "Login / Signup"}
                        </Button>
                    )}
                </div>
            </header>
        </>
    );
}
