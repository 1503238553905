import { db } from "../Config/Config";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import User from './User';
import { serverTimestamp, Timestamp } from "firebase/firestore";
import { HeadsetMic } from "@mui/icons-material";

class DBHandler {
    constructor(collectionName) {
        this.collectionName = collectionName;
    }

    doesUserExist = async (userId) => {
        try {
            const userRef = db.collection('users').doc(userId);
            const userSnapshot = await userRef.get();
            return userSnapshot.exists;
        } catch {
            return false;
        }
    }

    addUserData = async (user) => {
        if (user.id === '') return;

        const userData = {
            name: user.name,
            email: user.email,
            // countryCode: user.countryCode,
            phone: user.phone,
            isSubscribed: user.isSubscribed,
            updatedAt: serverTimestamp(),
            userType: 'User',
        };
        await db.collection('users').doc(user.id).set(userData);
    };

    getUser = async (uid) => {
        try {
            const userRef = db.collection('users').doc(uid);
            const userSnapshot = await userRef.get();

            if (userSnapshot.exists) {
                const userData = userSnapshot.data();

                const user = new User({
                    id: uid,
                    name: userData.name || '',
                    email: userData.email || '',
                    countryCode: userData.countryCode || '',
                    phone: userData.phone || '',
                    isSubscribed: userData.isSubscribed,
                    userType: userData.userType || 'User',
                });

                return user;
            } else {
                console.error('Error getting user. Please try again later!!');
                return new User();
            }
        } catch (error) {
            console.error('Error getting user:', error);
            return new User();
        }
    };

    generateQuestionId = () => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let uniqueCode = '';
        for (let i = 0; i < 10; i++) {
            uniqueCode += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return 'ques_' + uniqueCode;
    };

    addUserQuestion = async (user, questionData) => {
        if (user.id === '') return;

        try {
            const docRef = db.collection('questionUsers').doc(user.id);
            const quesHistoryRef = docRef.collection('quesHistory');
            const quesId = this.generateQuestionId();

            const dataUpdate = {
                id: user.id,
                name: user.name,
                email: user.email,
                heading: questionData.heading,
                description: questionData.description,
                additionalInfo: questionData.additionalInfo,
                questionId: quesId,
                updatedAt: serverTimestamp(),
            };

            const questionDocRef = quesHistoryRef.doc(quesId);
            await questionDocRef.set(dataUpdate);

        } catch (error) {
            console.error("Error adding user question: ", error);
        }
    };

}

export default DBHandler;
