import { createContext, useEffect, useReducer, useMemo } from "react";
import AuthReducer from "./AuthReducer";
import { auth } from "../Config/Config";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DBHandler from '../Utils/DBHandler';

const INITIAL_STATE = {
    currentUser: JSON.parse(localStorage.getItem("user")) || null,
    userType: null,
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
    // const { modifyUser } = useUser();
    // const { modifyPsychologist } = usePsychologist();
    const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

    const dbHandler = useMemo(() => new DBHandler(), []);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                try {
                    // const isPsyTrue = await dbHandler.doesPsychologistExist(user.uid);
                    const isUserTrue = await dbHandler.doesUserExist(user.uid);

                    if (isUserTrue) {
                        const userData = await dbHandler.getUser(user.uid);
                        dispatch({
                            type: "SET_USER",
                            payload: {
                                currentUser: userData,
                                userType: 'generalUser',
                            },
                        });
                    } else {
                        // toast.error("User data not found! Please contact the administrator.");
                    }
                } catch (error) {
                    toast.error("Error fetching user data!! Please check your internet connection. If the problem persists, please contact the administrator.");
                }
            } else {
                dispatch({
                    type: "SET_USER",
                    payload: {
                        currentUser: null,
                        userType: null,
                    },
                });
            }
        });

        return () => unsubscribe();
    }, [dbHandler]);

    useEffect(() => {
        localStorage.setItem("user", JSON.stringify(state.currentUser));
    }, [state.currentUser]);

    const setUserType = (userType) => {
        dispatch({ type: "SET_USER_TYPE", payload: userType });
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <AuthContext.Provider value={{ ...state, setUserType, dispatch }}>
                {children}
            </AuthContext.Provider>
        </>
    );
};
