export default class User {
    constructor({ id, name, email, phone, isSubscribed, userType }) {
        this.id = id;
        this.name = name;
        this.email = email;
        // this.countryCode = countryCode;
        this.phone = phone;
        this.isSubscribed = isSubscribed;
        this.userType = userType;
    }
}